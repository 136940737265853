import React, { useEffect, useState } from 'react';
import {
  useStaticQuery, graphql, Link,
} from 'gatsby';
import MLink from '../MLink';
import './Header.scss';
import HeaderMainMenu from './HeaderMainMenu';
import MDropdown from '../../../externals/components/MDropdown';

// const logoDark = '/images/MLReef_Logo_POS_H-01.svg';
// const logoLight = '/images/MLReef_Logo_Neg_H-01-light.svg';
const logoDark = '/images/MLReef_Logo_Neg_H.svg';
const logoLight = '/images/MLReef_Logo_POS_H.svg';

const Header = () => {
  const [isOnTop, setIsOnTop] = useState(true);
  const [menuShown, setMenuShown] = useState(false);
  const [whyMlreefLink, setWhyMlreefLink] = useState('https://www.mlreef.com/#why-users-love-mlreef');

  const {
    dataYaml: { header: data },
  } = useStaticQuery(
    graphql`
    query FetchHeaderData {
      site {
        siteMetadata {
          mlreefUrl
        }
      }

      dataYaml {
        header {
          links {
            label
            href
          }
        }
      }
    }
    `,
  );

  // console.log(data);

  const darkNavbarOnTop = () => {
    if (window) {
      setIsOnTop(!window.scrollY);
    }
  };

  useEffect(
    () => {
      if (window) {
        window.addEventListener('scroll', darkNavbarOnTop);
      }

      return () => {
        if (window) {
          window.removeEventListener('scroll', darkNavbarOnTop);
        }
      };
    },
    [],
  );

  useEffect(() => {
    let href;

    if (window) {
      href = window.location.href;

      if (href === 'https://www.mlreef.com/' || href === 'http://localhost:8000/') {
        setWhyMlreefLink('/#why-users-love-mlreef');
      }
    }
  }, []);

  const renderDropdown = ({ label, menu }) => (
    <MDropdown
      key={`header-menu-${label}`}
      label={label}
      buttonClasses="btn btn-dark px-3"
      component={(
        <div className="header-menu-content">
          <div className="header-menu-content-title">{menu.title}</div>
          <ul className="header-menu-content-list">
            {menu.items.map(item => (
              <li
                key={`menu-${label}-${item.label}`}
                className="header-menu-content-list-item"
              >
                <MLink to={item.href} className="nav-link">
                  <div style={{ color: item.color }} className="label">
                    <p style={{ fontWeight: 'bold', margin: '0.5rem 0 0 0' }}>
                      {item.label}
                    </p>
                  </div>
                  <div className="subtext">
                    {item.subtext}
                  </div>
                </MLink>
                <li>
                  <a href={whyMlreefLink}>
                    Product
                  </a>
                </li>
              </li>
            ))}
          </ul>
        </div>
      )}
    />
  );

  const renderLink = ({ label, href }) => (
    <MLink
      key={`header-link-${label}`}
      to={href}
      className="header-links-item btn btn-dark nav-link px-3 ml-3"
    >
      {label}
    </MLink>
  );

  return (
    <header className={`header ${isOnTop ? '' : 'light'}`}>
      <div className="header-section left flex-0">
        <MLink label="MLReef brand" to="/">
          <img
            alt="mlreef brand"
            className="header-brand m-auto"
            src={isOnTop ? logoDark : logoLight}
          />
        </MLink>
      </div>
      <div className="header-section center">
        <div className="header-links d-none d-lg-flex">
          <a
            className="header-links-item btn btn-dark nav-link px-3 ml-3"
            href={whyMlreefLink}
          >
            Product
          </a>
          {data.links.map(attrs => attrs.menu ? renderDropdown(attrs) : renderLink(attrs))}
        </div>
      </div>
      <div className="header-section right">
        <Link
          to="/book-a-demo"
          className="btn"
          style={{
            backgroundColor: '#eaba44',
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          Reach out
        </Link>
        <div className="header-account-links d-lg-none">
          <button
            type="button"
            className="main-menu-button btn btn-hidden fa fa-bars"
            label="menu"
            aria-label="menu"
            onClick={() => setMenuShown(true)}
          />
        </div>
      </div>
      <HeaderMainMenu
        whyMlreefLink={whyMlreefLink}
        items={data.links}
        shown={menuShown}
        setShown={setMenuShown}
      />
    </header>
  );
};

export default Header;
