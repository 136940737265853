import React from 'react';
import './WedgesBackground.scss';

const WedgesBackground = props => {
  const {
    wedges,
    className,
  } = props;

  const lefties = wedges.filter(({ align }) => align === 'left');
  const righties = wedges.filter(({ align }) => align === 'right');

  return (
    <>
      <div className={`wedges-background left ${className}`}>
        {lefties.map(w => (
          <div
            key={`wedges-${w.color}-${w.top}`}
            className={`wedge ${w.color} ${w.align} ${w.size ? w.size : ''}`}
            style={{ top: w.top }}
          />
        ))}
      </div>
      <div className={`wedges-background right ${className}`}>
        {righties.map(w => (
          <div
            key={`wedges-${w.color}-${w.top}`}
            className={`wedge ${w.color} ${w.align} ${w.size ? w.size : ''}`}
            style={{ top: w.top }}
          />
        ))}
      </div>
    </>
  );
};

WedgesBackground.defaultProps = {
  className: '',
  wedges: [],
};

export default WedgesBackground;
