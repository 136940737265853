import React from 'react';
import cx from 'classnames';

const containerStyles = {
  maxWidth: '90vw',
  maxHeight: '100vh',
  width: 'fit-content',
  height: 'fit-content',
};

const ImgModal = props => {
  const {
    className,
    show,
    alt,
    src,
    dispatch,
  } = props;

  const closeModal = () => {
    dispatch({ type: 'HIDE_IMAGE' });
  };

  const handleCoverClick = e => {
    e.stopPropagation();
    return closeModal();
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <div className={cx('modal modal-default dark-cover', className, { show })} style={{ zIndex: 1000 }}>
      {/* eslint-disable-next-line */}
      <div onClick={handleCoverClick} className="modal-cover" />
      <div className="modal-container" style={containerStyles}>
        <div className="modal-container-close" style={{ zIndex: 2, right: '-1.5rem', top: 0 }}>
          <button
            type="button"
            label="close modal"
            aria-label="close modal"
            className="btn btn-hidden fa fa-times"
            onClick={handleClose}
            style={{ color: 'var(--dark)', fontSize: '150%' }}
          />
        </div>
        <div className="modal-image-container">
          <img
            src={src}
            className="modal-image"
            alt={alt}
            style={{ maxWidth: '100%', maxHeight: '95vh', borderRadius: '2px' }}
          />
        </div>
      </div>
    </div>
  );
};

ImgModal.defaultProps = {
  className: '',
};

export default ImgModal;
