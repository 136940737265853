import React from 'react';
import { Link } from 'gatsby';
import MLink from '../MLink';

const logoDark = '/images/MLReef_Logo_POS_H-01.svg';
const logoLight = '/images/MLReef_Logo_Neg_H-01-light.svg';
const isOnTop = true;

const HeaderMainMenu = ({ items, shown, setShown, whyMlreefLink }) => (
  <div className={`header-main-menu-container ${shown ? 'shown' : ''}`}>
    <div className="header-main-menu">
      <div className="header-menu-content">
        <div className="header-main-menu-top flex-0">
          <Link to="/">
            <img
              alt="mlreef brand"
              className="header-brand m-auto"
              src={!isOnTop ? logoDark : logoLight}
            />
          </Link>
          <button
            type="button"
            className="main-menu-close btn btn-hidden fa fa-times"
            label="menu"
            aira-label="menu"
            onClick={() => setShown(false)}
          />
        </div>
        <ul className="header-menu-content-list first">
          <li
            className="header-menu-content-list-item"
          >
            <a
              className="nav-link"
              href={whyMlreefLink}
            >
              <div className="label">
                Product
              </div>
            </a>
          </li>
          {items.map(item => (
            <li
              key={`menu-${item.label}-${item.href}`}
              className="header-menu-content-list-item"
            >
              <MLink to={item.href} className="nav-link">
                <div className="label first">
                  {item.label}
                </div>
                {item.subtext && (
                  <div className="subtext">
                    {item.subtext}
                  </div>
                )}
              </MLink>
              <ul className="header-menu-content-list">
                {item.menu && item.menu.items.map(i => (
                  <li
                    key={`menu-${item.href}-${i.label}`}
                    className="header-menu-content-list-item"
                  >
                    <MLink to={i.href} className="nav-link">
                      <div style={{ color: i.color }} className="label">
                        {i.label}
                      </div>
                      <div className="subtext">
                        {i.subtext}
                      </div>
                    </MLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

HeaderMainMenu.defaultProps = {
  items: [],
};

export default HeaderMainMenu;
