import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import MLink from '../MLink';
import './Footer.scss';

const logoPath = '/images/MLReef_Logo_Neg.png';

const Footer = props => {
  // const { config } = props;

  const { dataYaml: { footer: data } } = useStaticQuery(
    graphql`
      query FetchFooterData {
        dataYaml {
          footer {
            menus {
              title
              items {
                href
                label
              }
            }
          }
        }
      }
    `,
  );

  const { menus } = data;

  return (
    <footer className="footer">
      <div className="footer-logo-container">
        <div className="footer-logo-container-post">
          <img alt="mlreef logo" className="footer-logo-image" src={logoPath} />
        </div>
      </div>

      <div className="footer-content">
        {menus && menus.map(({ title, items }) => (
          <div key={`menu-${title}`} className="footer-menu">
            <h3 className="footer-menu-title">{title}</h3>
            <ul className="footer-menu-list">
              {items && items.map(item => (
                <li key={`item-${item.label}`} className="footer-menu-list-item">
                  <MLink openApart className="nav-link" to={item.href}>
                    {item.label}
                  </MLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
