import React, { createContext, useReducer, useContext } from 'react';
import ImgModal from './ImgModal';

export const initialState = {
  show: false,
  src: '',
  options: {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_IMAGE':
      return {
        ...state,
        show: true,
        src: action.src,
      };

    case 'HIDE_IMAGE':
      return {
        ...state,
        show: false,
      };

    default:
      return state;
  }
};

export const Context = createContext();

export const useContextValue = () => useContext(Context);

export const ImgProvider = ({ children }) => (
  <Context.Provider value={useReducer(reducer, initialState)}>
    {children}
    <Context.Consumer>
      {
        ([state, dispatch]) => (
          <ImgModal {...state} dispatch={dispatch} />
        )
      }
    </Context.Consumer>
  </Context.Provider>
);

export default ImgProvider;
