import React from 'react';
import { useContextValue } from './ImgProvider';

const Img = props => {
  const {
    className,
    alt,
    src,
  } = props;

  const [, dispatch] = useContextValue();

  const openModal = () => {
    dispatch({ type: 'SHOW_IMAGE', src });
  };

  return (
    // eslint-disable-next-line
    <img
      src={src}
      className={className}
      alt={alt}
      onClick={openModal}
      style={{ cursor: 'pointer' }}
    />
  );
};

Img.defaultProps = {
  className: '',
};

export default Img;
