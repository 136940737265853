import React from 'react';
import './index.scss';
import { useStaticQuery, graphql } from 'gatsby';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer/Footer';
import { ImgProvider } from '../components/Img';
import WedgesBackground from '../components/WedgesBackground';
// import CookiesNotice from '../components/CookiesNotice';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Layout = props => {
  const {
    pathname,
    children,
    withWedges,
    className,
    title,
    lang,
    backgroundEnabled
  } = props;

  const {
    dataYaml: { WedgesBackground: { wedges } },
    site: { siteMetadata: { title: defaultTitle, url } },
  } = useStaticQuery(
    graphql`
    query LayoutData {
      dataYaml {
        WedgesBackground {
          wedges {
            align
            color
            top
            size
          }
        }
      }

      site {
        siteMetadata {
          title
          url
        }
      }
    }
    `,
  );

  return (
    <QueryClientProvider client={queryClient}>
      <div className={`layout-container ${backgroundEnabled}`}>
        <Helmet>
          <html lang={lang} />
          <title>
            {title ? `${title} | MLReef` : defaultTitle}
          </title>
          <link rel="canonical" href={`${url}${pathname}`} />
        </Helmet>
        <ImgProvider>
          <Header />
          <main className={className}>
            {children}
          </main>
          {withWedges && (
            <WedgesBackground className="d-none d-lg-block" wedges={wedges} />
          )}
          <Footer />
        </ImgProvider>
      </div>
    </QueryClientProvider>
  );
};

Layout.defaultProps = {
  className: '',
  pathname: '',
  lang: 'en',
};

export default Layout;
